import { FilterDropdown, Tabs } from '@monash/portal-react';
import React, { useState, useContext } from 'react';
import { SearchContext } from '../../providers/search-provider/SearchProvider';
import PageContainer from '../../layouts/page-container/PageContainer';
import dummyLinks from '../../utilities/data/dummy-links.json';
import SearchResultsCard from '../../ui/search-result-card/SearchResultsCard';
import filterLinksByCategory from '../../utilities/filter-and-sort/filterLinksByCategory';
import c from './all-links.module.scss';
import sortLinksAtoZ from '../../utilities/filter-and-sort/sortLinksAtoZ';
import sortLinksByVisited from '../../utilities/filter-and-sort/sortLinksByVisited';
import SearchError from '../search/SearchError';
import { categoryData } from '../category/data';

const AllLinks = () => {
  const { allLinks, loadingLinks, searchError } = useContext(SearchContext);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const TABS_ID_PREFIX = 'links';

  const sortMethods = [
    { label: 'Most visited', func: (links) => sortLinksByVisited(links) },
    { label: 'A-Z', func: (links) => sortLinksAtoZ(links) },
    { label: 'Z-A', func: (links) => sortLinksAtoZ(links).reverse() },
  ];
  const [selectedFilter, setSelectedFilter] = useState(sortMethods[0].label);

  const displayAllLinks =
    sortMethods[sortMethods.findIndex((x) => x.label === selectedFilter)]?.func(
      allLinks
    ) || allLinks;

  const categories = categoryData.map((c) => c.name);

  const filteredLinks = filterLinksByCategory(
    [categories[selectedTabIndex - 1]],
    displayAllLinks
  );

  const displayLinks = loadingLinks
    ? dummyLinks
    : selectedTabIndex === 0
    ? displayAllLinks
    : filteredLinks;

  return (
    <PageContainer type="default">
      {searchError && <SearchError />}
      {!searchError && (
        <div>
          <Tabs
            tabs={['All', ...categories]}
            selected={selectedTabIndex}
            onChange={setSelectedTabIndex}
            label="Links category tabs"
            idPrefix={TABS_ID_PREFIX}
          />
          <div
            className={c.contentContainer}
            id={`${TABS_ID_PREFIX}-tabpanel-${selectedTabIndex}`}
            role="tabpanel"
            aria-labelledby={`${TABS_ID_PREFIX}-tab-button-${selectedTabIndex}`}
            tabIndex="0"
          >
            <div className={c.contentHeader}>
              <span tabIndex="-1">{displayLinks.length} links</span>
              <FilterDropdown
                filters={sortMethods.map((x) => x.label)}
                selectedFilter={selectedFilter}
                setSelectedFilter={setSelectedFilter}
              />
            </div>
            <div className={c.links}>
              {displayLinks.map((item, i) => (
                <SearchResultsCard
                  entity={item.entityContent}
                  key={i}
                  index={i}
                  loading={Boolean(loadingLinks)}
                />
              ))}
            </div>
          </div>
        </div>
      )}
    </PageContainer>
  );
};

export default AllLinks;
